@import "./variables.scss";

@import "./layout.scss";
@import "./nav.scss";
@import "./login.scss";
@import "./custom-react-tag.scss";

.search-condition {
  margin-top: 30px;

  button {
    white-space: nowrap;
    height: 38px;
  }

  .btn-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
.condition-date-picker {
  display: flex;
  align-items: center;
}

.react-datepicker-wrapper {
  width: initial;
}

.search-result {
  margin-top: 20px;

  .table-wrapper {
    .pagination-wrapper {
      display: flex;
      justify-content: center;
    }

    .count-block {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.detail {
  .detail-row {
    margin: 10px;
    display: flex;
    align-items: center;

    .label {
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
  }

  .studentList {
    flex-direction: column;
    align-items: baseline;

    > .label {
      margin-bottom: 10px;
    }

    .studentItems > div {
      padding: 5px 0;
    }
  }
}

.student-info {
  .level-table {
    width: 100%;
    border: solid 1px #dee2e6;
    border-bottom: none;
    text-align: center;
    border-collapse: initial;

    th {
      background-color: black;
      color: white;
    }
    th, td {
      border-right: solid 1px #dee2e6;
      border-bottom: solid 1px #dee2e6;
      padding: 10px;
    }
    th:last-child, td:last-child {
      border-right: none;
    }
  }

  .zone-info {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 0 20%;
      text-align: center;
      border: solid 1px #dee2e6;
      padding: 20px;
      opacity: 0.5;
      margin-bottom: 10px;
      background-color: #808080;

      &.active {
        opacity: 1;
        background-color: initial;
      }
    }
  }
}

.report-table tbody {
  border-top: none !important;
}

.deleted-student {
  background-color: #d3d3d3;
}

.dmLogSearchTable {
  font-size: 0.9rem;

  tr {
    border-color: #525252;
  }
  .hl-row {
    padding: 5px;
  }
  .hl-row:not(:last-child) {
    border-bottom: solid 1px #c8c8c9;
  }
}
