#login-wrapper {
  height: 100vh;
  color: $base-font-color;

  .login-content {
    max-width: 540px;
    margin: 8vh auto;
  }
  .login-title {
    text-align: center;
    font-size:30px;
    font-weight: bold;
  }
  .login-form {
    padding: 30px;
    border: solid 1px #DCDCDC;
  }
}