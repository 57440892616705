.react-tagsinput-tag {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;
}

.react-tagsinput-tag a::before {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tagsinput--focused {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-tagsinput-remove {
  text-decoration: none;
}

.react-tagsinput-input {
  color: #212529;
}
.react-tagsinput-tag {
  color: #212529;
}
