#sidebar {
  min-width: $nav-max-width;
  max-width: $nav-max-width;
  background: $base-color;
  color: $base-font-color;

  .logo {
    width: 200px;
    height: 82px;
    margin: auto;
  }

  ul {
    padding: 0;
    list-style: none;

    li > ul {
      margin-left: 25px;
    }

    li a {
      padding: 10px 0;
      display: block;
      position: relative;
      color: rgba(255,255,255,.8);
      border-bottom: 1px solid rgba(255,255,255,.1);
      text-decoration: none;
    }

    li.active > a {
      color: #f8b739;
    }

    .dropdown-toggle::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}